@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
.product {
  position: relative;
  width: 50%;
}

.product:hover .overlay {
  bottom: 0;
  height: 300px;
}

.subtext {
  color: rgb(17, 17, 17);
  font-size: 20px;
  position: relative;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
}

.productoverlay {
  position: absolute;
  bottom: 100%;
  left: 70px;
  right: 70px;
  background-color: #ffffff;
  overflow: hidden;
  width: auto;
  height: 0;
  transition: 0.5s ease;
  top: 75px;
}

.productcollections {
  color: rgb(17, 17, 17);
  font-size: 20px;
  position: relative;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}


.menudropdown {
  float: left;
  overflow: hidden;
  width: 100%;
}


.menudropdown-content {
  display: none;
  position: fixed;
  left: 10%;
  padding-top: 1rem;
}


.menucolumn {
  padding: 1rem;
  vertical-align: bottom;
  align-items: center;
  text-align: center;
}

.dropmenu-image {
  display: none;
  padding: 1rem;
}

.feature-table {
  display: none;
}

@media (min-width: 62em) {
  .feature-table {
    display: block;
  }
}

.zoom {
  transition: transform 0.5s;
  margin: 0 0;
}

.zoom:hover {
  transform: scale(1.05);
  z-index: 1;
}

.image-center {
  text-align: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.animation {
  background: rgba(230, 236, 247, 0.82);
  overflow: hidden;
  z-index: 2;
}
.FooterHr {
  color: pink;
}
.FLocationIcon {
  font-size: 30px;
  padding: 0px 20px 20px 20px;
}
.FPhoneIcon {
  font-size: 20px !important;
  padding: 24px 20px 20px 20px;
}

.TabText {
  color: gray !important;
  font-size: 16px;
}
.TabText :hover {
  color: black !important;
}
.TabHr {
  background-color: #d8d3d3;
  margin-top: 6px;
  margin-bottom: 8px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
  justify-content: center;
}

.TextCW a {
  color: white !important;
}

.breadcrumb-item .nav-link {
  padding: 0;
  display: flex;
}

.nav-link {
  color: black;
}

.navbar-nav > .nav-link:hover {
  color: #1da9d2 !important;
  /* border-bottom: 2px solid #1da9d2 !important; */
  width: 50%;
}

.nav-link.active {
  color: #1da9d2 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: transparent;
}
.btn-primary {
  width: 215px;
  background-color: transparent !important;
  color: black !important;
  border: 1px solid #897d7d;
}
.btn-primary:hover {
  width: 215px;
  background-color: transparent !important;
  color: black;
  border: 1px solid #897d7d;
}
.MeterDropdown {
  float: right;
  padding: 5px;
}

.alert-box {
  width: 100%;
  padding: 1rem 0.5rem 0;
  border-radius: 5px;
  border: 2px solid rgba(32, 32, 96, 0.811);
  color: rgba(32, 32, 96, 0.811);
  margin-top: 1rem;
  box-shadow: 0px 0px 2px rgba(32, 32, 96, 0.811);
}

.show > .btn-primary.dropdown-toggle {
  color: black;
}

.FooterBox {
  background-color: #647a93;
  color: "white";
  margin-top: 100px;
  color: white !important;
  padding-top: 50px;
}
.FLogo {
  width: 90px;
  height: 70px;
}
.FooterC {
  padding-right: 4%;
  padding-left: 3%;
}
.FText {
  font-size: 18px;
  margin-top: 40px;
}
.FLinkHeader {
  margin-bottom: 40px;
  font-size: 26px;
  margin-top: 40px;
}
.FLink {
  font-size: 14px;
  color: white;
  cursor: pointer;
}
.FLink:hover {
  font-size: 14px;
  color: rgb(32, 32, 96) !important;
}
.Address {
  font-size: 14px;
}
.FooterB {
  background-color: black;
  padding-top: 15px;
  padding-left: 3%;
}
.FCopyright {
  color: gray;
  font-size: 14px;
}
.FooterHr {
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: white;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (max-width: 575px) {
  html,
  body {
    padding: 0px;
    margin: 0px;
    width: 100%;
    overflow-x: hidden;
  }

  .MeterDropdown {
    float: left;
  }
}

@media (max-width: 700px) {
  .zoom {
    width: 183px;
  }

  .CardContainer {
    margin-top: 20px;
  }

  .ProductImage {
    width: 70% !important;
    margin-top: 10%;
    margin-left: 15%;
  }

  .Middle {
    left: 48% !important;
  }
}

@media (max-width: 1050px) {
  .TopNav {
    display: none;
  }
}
.CenterTab {
  text-align: center;
  justify-content: center;
  background-color: white;
  margin-top: 30px;
  border-bottom: 1px solid gray !;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: green;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid green !important;
}
.nav-tabs {
  border-bottom: 1px solid gray;
}
.ListItems {
  list-style: disc;
  font-size: 18px;
}
.Categories {
  color: gray;
}
.ProductHeaderBox {
  background-color: #647a93;
  color: white;
  text-align: center;
}
.ProductB {
  justify-content: center;
  background-color: transparent;
  padding: 5px;
}
.TextCW {
  color: white !important;
}
.TextCB {
  color: black;
}
.ProductsIcon {
  border: 1px solid gray;
  padding: 10px;
  margin-left: 8px;
  color: black;
}
.ProductsIcon:hover {
  background-color: blue;
  color: white;
}
.UserText {
  margin-top: 15px;
  margin-left: 20px;
}
.ProductContainer {
  position: relative;
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.BoxBorder {
  border: "0.5px solid gray" !important;
}
.CardContainer {
  grid-gap: 15px;
  gap: 15px;
}
.SingleTabText {
  margin-right: 50px !important;
}
.ProductTab {
  padding-left: 20px;
  margin-top: 55px;
}
.SingalDigitalText {
  padding-left: 15px;
  font-size: 18px;
}
.CardBox {
  border: 1px solid gray !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.CardText {
  text-align: center;
  font-size: 1rem;
  padding-top: 10px;
}
.DefaultCardText {
  text-align: center;
  font-size: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ProductImage {
  opacity: 1;
  display: block;
  width: 100%;
  min-height: 235px;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.DefaultProductImage {
  opacity: 1;
  display: block;
  width: 100%;
  height: 200px !important;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.Middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 82px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.ProductContainer:hover .ProductImage {
  opacity: 0.3;
  background-color: rgba(0, 0, 0, 0.56);
}

.navbar-nav > .current {
  color: #1da9d2 !important;
  border-bottom: 2px solid #1da9d2 !important;
}

.ProductContainer:hover .Middle {
  opacity: 1;
}

.ReadmoreText {
  background-color: rgb(32, 32, 96);
  color: white;
  font-size: 18px;
  padding: 10px 5px;
  width: 110px;
  text-align: center;
  cursor: pointer;
}
.PFBIcon {
  padding-left: 6px;
  padding-right: 6px;
}
.PTIcon {
  padding-left: 3px;
  padding-right: 3px;
}
.InputIcon {
  margin-top: 22px;
}

.chip {
  background-color: rgb(255, 0, 0);
  float: right;
  padding: 5px;
  border: 2px solid;
  font-size: 16px;
  color: #f2f2f2;
}

.menunavbar {
  overflow: hidden;
  background-color: #333;
  font-family: Arial;
}


.menunavbar a {
  float: left;
  font-size: 16px;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}


.menudropdown h6 {
  font-size: 12px;
  color: red;
}


.menudropdown .dropbtn {
  font-size: 12px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: pink;
  font: inherit;
  margin: 0;
}


.menunavbar a:hover,
.menudropdown:hover .menudropbtn {
  background-color: rgb(235, 245, 233);
  text-decoration: none;
}

.menudropdown-content {
  display: none;
}

.menudropdown-content h5 {
  padding-bottom: -0.5rem;
  color: white;
  font-size: medium;
}

.menudropdown:hover .menudropdown-content {
  z-index: 5;
  display: list-item;
  width: 95%;
  background-color: rgb(82, 104, 84);
  bottom: 5%;
  height: 85%;
  border-radius: 20px;
  border: 1px;
}

.menudropdown-content .dropdownLink {
  color: white;
  font-size: 12px;
}


.link-container a {
  display: block;
  text-transform: capitalize;
  font-size: 12px;
  padding-bottom: 2px;
}

.link-container a:hover {
  background: transition;
  border: 1px;
  border-radius: 10px;
}


.menurow:after {
  display: table;
  clear: both;
}

.divider-line {
  border: 1px solid grey;
}

.productimg {
  align-self: center;
}

.imagecenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.overlaydm {
  position: absolute;
  bottom: 100%;
  left: 70px;
  right: 70px;
  background-color: #ffffff;
  overflow: hidden;
  width: auto;
  height: 0;
  transition: 0.5s ease;
  top: 70px;
}

.dropmenu-image hr {
  width: 100px;
}

@media (min-width: 62em) {
  .dropmenu-image {
    display: inline-flex;
    height: 80px;
    width: 70px;
  }
  .menudropdown-content ul {
    list-style-type: none;
    line-height: 3rem;
    margin-left: 20px;
  }
  .menudropdown-content .dropdownLink {
    color: white;
    font-size: 16px;
  }
  .menudropdown:hover .menudropdown-content {
    display: flex;
    padding-top: 1rem;
    position: fixed;
    width: 96%;
    height: auto;
    background-color: rgb(82, 104, 84);
    border-radius: 20px;
    border: 2px;
    padding-left: 10%;
    margin-top: 1px;
    bottom: auto;
  }
  .menudropdown-content h5 {
    padding: 4px;
    color: white;
    font-size: medium;
  }
}

.menudropdown-content ul {
  list-style-type: none;
  line-height: 0.75rem;
  line-break: loose;
}

.carousel-indicators {
  bottom: 10px;
}
.carousel-indicators li {
  border-radius: 50%;
  background-color: #cabcbc; 
  height: 15px;
  width: 15px;

  border: 1px solid gray;
}
.carousel-indicators li.active {
  background-color: #ffffff; 
  border: 3px solid black;
}
.carousel-fade .carousel-inner .carousel-item {
  transition: opacity 1s ease-in-out; 
}

.carousel-control-next-icon {
  color: rgb(222, 191, 191) !important;
}

.carousel-control-prev-icon {
  color: white;
}

body {
  color: #000000;
  font-size: 18px;
}

a:hover {
  text-decoration: none;
}


.carousel {
  margin-bottom: 4rem;
}

.carousel-caption1 {
  bottom: 3rem;
  margin-top: 18rem;
  color: rgb(187, 70, 70);
}

.carousel-item {
  height: 70vw;
}

.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  height: 70vw;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 1.15s ease;
}


.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.marketing h2 {
  font-weight: 400;
}

.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}


.featurette-divider {
  margin: 3.5rem 0rem;
}

.fa {
  text-align: center;
  text-decoration: none;
}


.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}


@media (min-width: 40em) {
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
  .carousel-item {
    height: 35vw;
  }
  .carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    height: 35vw;
  }
}
.CarouselImage {
  width: 40%;
  height: 500px;
  float: right;
}
.CarouselText {
  float: left;
  width: 55%;
  margin-left: 25px;
}
.CarouselTextBox {
  float: left;
  width: 55%;
}
.CTextHeading {
  color: rgb(32, 32, 96);
}
.CText {
  font-size: 15px !important;
}
.carousel-caption {
  position: absolute;
  right: 8%;
  left: 1%;
  top: 28%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: black;
  text-align: left;
  padding-left: 55px;
  padding-right: 40px;
}
.QRImage {
  height: 100px;
  width: 120px;
  margin-left: 30px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.ScanText {
  background-color: rgb(32, 32, 96);
  border-radius: 8px;
  color: white;
  padding: 2px;
  width: 120px;
  margin-bottom: 25px;
  font-size: 15px !important;
  text-align: center;
}

.bgImage {
  background-image: url(/static/media/electical-panels.865b4c65.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: difference;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.4);
  min-width: 100%;
}

@media (max-width: 700px) {
  .CTextHeading {
    font-size: 22px;
  }

  .CText {
    font-size: 14px !important;
  }

  .bgImage {
    height: 455px;
  }

  .carousel-caption {
    top: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
    color: black;
    text-align: left;
    padding-left: 15px;
    padding-right: 35px;
  }

  .CarouselImage {
    width: 30%;
    height: 260px;
    margin-top: 120px;
    margin-left: 30px;
  }
}


.overlay-content {
  position: relative;
  top: 15%;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.overlay a {
  text-decoration: none;
  color: #f0f0f0;
}

.overlay a:hover {
  color: powderblue;
  text-decoration: none;
}

.overlay .closebtn {
  position: absolute;
  top: 10px;
  right: 45px;
  font-size: 40px;
  color: #f0f0f0;
  z-index: 1;
}

.fixed-top {
  position: sticky;
  top: 35pxs;
  width: 100% !important;
  padding: 5px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 10px;
    right: 35px;
  }
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}

.openbtn a:hover {
  font-size: 20px;
  cursor: pointer;
  background-color: rgba;
  color: white;
  padding: 10px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

ul {
  list-style-type: none;
}

.navBarUL Link {
  list-style-type: none;
  color: rgb(255, 255, 255);
}

.caret {
  font-size: 10px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 1rem;
  font-weight: bolder;
  color: rgb(205, 182, 50);
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  transform: rotate(90deg);
}

.active {
  display: block;
  color: black;
}

.column {
  float: left;
  width: 33.33%;
  padding: 4px;
  background-color: none;
  height: auto;
  display: block;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: navy;
}

li .product {
  font-size: 15px;
  font-weight: bolder;
  color: rgb(205, 182, 50);
}

.bg-dark {
  background-color: black;
}

.bg-light {
  background-color: "#D4E6C7";
}

.hide {
  display: none;
}

li a:hover + .hide {
  display: block;
}

nav {
  background-color: black;
}

.TopNav {
  width: 100%;
  padding-right: 4%;
  padding-left: 3%;
}
.LeftElement {
  font-size: 16px;
  display: flex;
  margin-top: 7px;
}
.EnvelopeIcon {
  font-size: 20px;
  color: black;
}
.EnvelopeIcon:hover {
  color: black;
}
.EnvelopeText {
  margin-left: 1rem;
  color: black;
}
.PhoneIcon {
  padding-left: 1rem;
  padding-top: 5px;
}
.PhoneNum {
  padding-left: 8px;
}
.RightElement {
  text-align: right;
  margin-top: 7px;
  padding-left: 0;
}
.LinkIcons {
  color: black;
  padding-left: 40px;
}
.LinkIcons:hover {
  color: gray !important;
}
.ProductDropdown:hover {
  color: gray !important;
}

.DropdownContent {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px !important;
  background-color: white !important;
  width: auto !important;
  padding-left: 0px !important;
  position: absolute !important;
  left: 80% !important;
  top: auto !important;
  height: auto !important;
  padding-right: 40px;
}
.ProductDropdown {
  visibility: hidden;
  float: right;
  color: black;
}
.ProductDropdown:hover {
  color: black;
}
.HeaderBg {
  background-color: white;
  padding-right: 4%;
  padding-left: 3%;
}
.DropdownLink {
  color: rgb(32, 32, 96);
  font-size: 18px;
  padding-left: 0;
}
#navbar {
  box-shadow: none;
  transition: top 0.3s;
}
#GoTOTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 16px;
  border: none;
  color: white;
  background-color: rgb(32, 32, 96);
  display: none;
  z-index: 99;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
}

.AlignCenter {
  text-align: center;
}
.navbar-light .navbar-nav .nav-link {
  color: black;
}
@media screen and (min-width: 1050px) {
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: rgb(32, 32, 96);
  }
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #1da9d2;
  /* border-bottom: 2px solid #1da9d2; */
}
.selected {
  color: pink;
  text-decoration: underline;
}

.BackgroundImage {
  background-size: cover;
  background-repeat: repeat;
  background-color: rgba(0, 0, 0, 0);
  background-image: url(/static/media/What-is-systems-engineering-cover-art-01.02ca0cb9.6e97cdf3.webp);
  background-attachment: scroll;
  min-width: 100%;
  background-position: center center;
}
.AboutText {
  padding-top: 140px;
  padding-bottom: 124px;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.56);
}
.Text {
  font-size: 17px;
}
.AboutContainer {
  padding-left: 30px;
  padding-right: 30px;
}
.AboutContainerHeader {
  text-align: center;
  margin-top: 90px;
  margin-bottom: 40px;
}
.OureVisitionContainer {
  margin-top: 80px;
}
.OurVisionText {
  margin-bottom: 30px;
  text-align: center;
}

.Gmap {
  width: 100%;
  height: 450px;
  margin-bottom: 50px;
}
.ContactContainer {
  padding-left: 20px;
  padding-right: 20px;
}
.GetSocialBox {
  margin-bottom: 50px;
  margin-top: 60px;
}
.ContactInfoText {
  font-size: 17px;
  padding-left: 24px;
}
.FBIcon {
  background-color: #37589b !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.InstIcon {
  background-color: #5996c7 !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.TwtitterIcon {
  background-color: #46d4fe !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.GIcon {
  background-color: #de5a49 !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.Icon {
  color: white;
  background-color: blue;
  padding: 6px 10px;
  font-size: 15px;
  border-radius: 6px;
  margin-right: 12px;
}
.FlexItem {
  display: flex;
}

.HomeIcon:hover {
  color: rgb(32, 32, 96) !important  ;
}
.circle {
  display: inline-flex;
  font-size: 30px;
  align-items: center;
  margin-top: 10px;
  padding: 29px;
  color: rgb(32, 32, 96);
  margin-bottom: 10px;
  justify-content: center;
  border: 1px solid rgb(32, 32, 96);
  width: 50px; 
  height: 50px; 
  border-radius: 50%; 
  background-color: transparent;
}
.HomeIcon:hover {
  color: gray;
}
.HomeText {
  margin-top: 20px;
}
.LoremText {
  margin-top: 34px;
}
.MassageBox {
  height: 160px !important;
  padding-bottom: 120px;
}
.ErrorMessages {
  margin-bottom: 0px;
  color: rgb(32, 32, 96);
  margin-top: -30px;
}
.ErrorMessage {
  margin-bottom: 20px;
  color: rgb(32, 32, 96);
  margin-top: -30px;
}
.InputField {
  width: 100%;
  padding-left: 50px;
  color: gray;
  border: 1px solid rgb(150, 148, 148) !important;
  height: 45px;
  margin-bottom: 31px;
}
.MassageInput {
  height: 160px;
  padding-bottom: 120px;
}

.InputIcon i {
  position: absolute;
}
.Icons {
  padding: 15px 20px 10px 10px;
  min-width: 40px;
}
.SubmitButton {
  background-color: rgb(32, 32, 96);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 3px;
  margin-top: 20px;
}
.TabText {
  color: gray !important;
  font-size: 16px;
}
.TabText :hover {
  color: #1da9d2 !important;
}
.TabHr {
  background-color: #d8d3d3;
  margin-top: 6px;
  margin-bottom: 8px;
}
.SingleTab {
  margin-left: -16px;
  margin-bottom: -2px;
  margin-top: -4px;
}
.PowerTab {
  margin-top: -15px;
  margin-bottom: -4px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
  justify-content: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: transparent;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: white;
  content: "/";
}
.btn-primary {
  width: 215px;
  background-color: transparent !important;
  color: black;
  border: 1px solid #897d7d;
}
.btn-primary:hover {
  width: 215px;
  background-color: transparent !important;
  color: black;
  border: 1px solid #897d7d;
}
.show > .btn-primary.dropdown-toggle {
  color: black;
}
@media screen and (max-width: 991px) {
  .ProductDropdown {
    visibility: visible;
  }
  .DropdownContent {
    left: 14% !important;
  }
}
@media (max-width: 1050px) {
  .TopNav {
    display: none;
  }
}

