
.overlay-content {
  position: relative;
  top: 15%;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.overlay a {
  text-decoration: none;
  color: #f0f0f0;
}

.overlay a:hover {
  color: powderblue;
  text-decoration: none;
}

.overlay .closebtn {
  position: absolute;
  top: 10px;
  right: 45px;
  font-size: 40px;
  color: #f0f0f0;
  z-index: 1;
}

.fixed-top {
  position: -webkit-sticky;
  position: sticky;
  top: 35pxs;
  width: 100% !important;
  padding: 5px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 10px;
    right: 35px;
  }
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}

.openbtn a:hover {
  font-size: 20px;
  cursor: pointer;
  background-color: rgba;
  color: white;
  padding: 10px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

ul {
  list-style-type: none;
}

.navBarUL Link {
  list-style-type: none;
  color: rgb(255, 255, 255);
}

.caret {
  font-size: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 1rem;
  font-weight: bolder;
  color: rgb(205, 182, 50);
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.active {
  display: block;
  color: black;
}

.column {
  float: left;
  width: 33.33%;
  padding: 4px;
  background-color: none;
  height: auto;
  display: block;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: navy;
}

li .product {
  font-size: 15px;
  font-weight: bolder;
  color: rgb(205, 182, 50);
}

.bg-dark {
  background-color: black;
}

.bg-light {
  background-color: "#D4E6C7";
}

.hide {
  display: none;
}

li a:hover + .hide {
  display: block;
}

nav {
  background-color: black;
}

.TopNav {
  width: 100%;
  padding-right: 4%;
  padding-left: 3%;
}
.LeftElement {
  font-size: 16px;
  display: flex;
  margin-top: 7px;
}
.EnvelopeIcon {
  font-size: 20px;
  color: black;
}
.EnvelopeIcon:hover {
  color: black;
}
.EnvelopeText {
  margin-left: 1rem;
  color: black;
}
.PhoneIcon {
  padding-left: 1rem;
  padding-top: 5px;
}
.PhoneNum {
  padding-left: 8px;
}
.RightElement {
  text-align: right;
  margin-top: 7px;
  padding-left: 0;
}
.LinkIcons {
  color: black;
  padding-left: 40px;
}
.LinkIcons:hover {
  color: gray !important;
}
.ProductDropdown:hover {
  color: gray !important;
}

.DropdownContent {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px !important;
  background-color: white !important;
  width: auto !important;
  padding-left: 0px !important;
  position: absolute !important;
  left: 80% !important;
  top: auto !important;
  height: auto !important;
  padding-right: 40px;
}
.ProductDropdown {
  visibility: hidden;
  float: right;
  color: black;
}
.ProductDropdown:hover {
  color: black;
}
.HeaderBg {
  background-color: white;
  padding-right: 4%;
  padding-left: 3%;
}
.DropdownLink {
  color: rgb(32, 32, 96);
  font-size: 18px;
  padding-left: 0;
}
#navbar {
  box-shadow: none;
  transition: top 0.3s;
}
#GoTOTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 16px;
  border: none;
  color: white;
  background-color: rgb(32, 32, 96);
  display: none;
  z-index: 99;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
}

.AlignCenter {
  text-align: center;
}
.navbar-light .navbar-nav .nav-link {
  color: black;
}
@media screen and (min-width: 1050px) {
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: rgb(32, 32, 96);
  }
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #1da9d2;
  /* border-bottom: 2px solid #1da9d2; */
}
.selected {
  color: pink;
  text-decoration: underline;
}

.BackgroundImage {
  background-size: cover;
  background-repeat: repeat;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("../images/productsimages/What-is-systems-engineering-cover-art-01.02ca0cb9.webp");
  background-attachment: scroll;
  min-width: 100%;
  background-position: center center;
}
.AboutText {
  padding-top: 140px;
  padding-bottom: 124px;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.56);
}
.Text {
  font-size: 17px;
}
.AboutContainer {
  padding-left: 30px;
  padding-right: 30px;
}
.AboutContainerHeader {
  text-align: center;
  margin-top: 90px;
  margin-bottom: 40px;
}
.OureVisitionContainer {
  margin-top: 80px;
}
.OurVisionText {
  margin-bottom: 30px;
  text-align: center;
}

.Gmap {
  width: 100%;
  height: 450px;
  margin-bottom: 50px;
}
.ContactContainer {
  padding-left: 20px;
  padding-right: 20px;
}
.GetSocialBox {
  margin-bottom: 50px;
  margin-top: 60px;
}
.ContactInfoText {
  font-size: 17px;
  padding-left: 24px;
}
.FBIcon {
  background-color: #37589b !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.InstIcon {
  background-color: #5996c7 !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.TwtitterIcon {
  background-color: #46d4fe !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.GIcon {
  background-color: #de5a49 !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.Icon {
  color: white;
  background-color: blue;
  padding: 6px 10px;
  font-size: 15px;
  border-radius: 6px;
  margin-right: 12px;
}
.FlexItem {
  display: flex;
}

.HomeIcon:hover {
  color: rgb(32, 32, 96) !important  ;
}
.circle {
  display: inline-flex;
  font-size: 30px;
  align-items: center;
  margin-top: 10px;
  padding: 29px;
  color: rgb(32, 32, 96);
  margin-bottom: 10px;
  justify-content: center;
  border: 1px solid rgb(32, 32, 96);
  width: 50px; 
  height: 50px; 
  border-radius: 50%; 
  background-color: transparent;
}
.HomeIcon:hover {
  color: gray;
}
.HomeText {
  margin-top: 20px;
}
.LoremText {
  margin-top: 34px;
}
.MassageBox {
  height: 160px !important;
  padding-bottom: 120px;
}
.ErrorMessages {
  margin-bottom: 0px;
  color: rgb(32, 32, 96);
  margin-top: -30px;
}
.ErrorMessage {
  margin-bottom: 20px;
  color: rgb(32, 32, 96);
  margin-top: -30px;
}
.InputField {
  width: 100%;
  padding-left: 50px;
  color: gray;
  border: 1px solid rgb(150, 148, 148) !important;
  height: 45px;
  margin-bottom: 31px;
}
.MassageInput {
  height: 160px;
  padding-bottom: 120px;
}

.InputIcon i {
  position: absolute;
}
.Icons {
  padding: 15px 20px 10px 10px;
  min-width: 40px;
}
.SubmitButton {
  background-color: rgb(32, 32, 96);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 3px;
  margin-top: 20px;
}
.TabText {
  color: gray !important;
  font-size: 16px;
}
.TabText :hover {
  color: #1da9d2 !important;
}
.TabHr {
  background-color: #d8d3d3;
  margin-top: 6px;
  margin-bottom: 8px;
}
.SingleTab {
  margin-left: -16px;
  margin-bottom: -2px;
  margin-top: -4px;
}
.PowerTab {
  margin-top: -15px;
  margin-bottom: -4px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
  justify-content: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: transparent;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: white;
  content: "/";
}
.btn-primary {
  width: 215px;
  background-color: transparent !important;
  color: black;
  border: 1px solid #897d7d;
}
.btn-primary:hover {
  width: 215px;
  background-color: transparent !important;
  color: black;
  border: 1px solid #897d7d;
}
.show > .btn-primary.dropdown-toggle {
  color: black;
}
@media screen and (max-width: 991px) {
  .ProductDropdown {
    visibility: visible;
  }
  .DropdownContent {
    left: 14% !important;
  }
}
@media (max-width: 1050px) {
  .TopNav {
    display: none;
  }
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
