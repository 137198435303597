.carousel-indicators {
  bottom: 10px;
}
.carousel-indicators li {
  border-radius: 50%;
  background-color: #cabcbc; 
  height: 15px;
  width: 15px;

  border: 1px solid gray;
}
.carousel-indicators li.active {
  background-color: #ffffff; 
  border: 3px solid black;
}
.carousel-fade .carousel-inner .carousel-item {
  transition: opacity 1s ease-in-out; 
}

.carousel-control-next-icon {
  color: rgb(222, 191, 191) !important;
}

.carousel-control-prev-icon {
  color: white;
}

body {
  color: #000000;
  font-size: 18px;
}

a:hover {
  text-decoration: none;
}


.carousel {
  margin-bottom: 4rem;
}

.carousel-caption1 {
  bottom: 3rem;
  margin-top: 18rem;
  color: rgb(187, 70, 70);
}

.carousel-item {
  height: 70vw;
}

.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  height: 70vw;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 1.15s ease;
}


.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.marketing h2 {
  font-weight: 400;
}

.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}


.featurette-divider {
  margin: 3.5rem 0rem;
}

.fa {
  text-align: center;
  text-decoration: none;
}


.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}


@media (min-width: 40em) {
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
  .carousel-item {
    height: 35vw;
  }
  .carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    height: 35vw;
  }
}
.CarouselImage {
  width: 40%;
  height: 500px;
  float: right;
}
.CarouselText {
  float: left;
  width: 55%;
  margin-left: 25px;
}
.CarouselTextBox {
  float: left;
  width: 55%;
}
.CTextHeading {
  color: rgb(32, 32, 96);
}
.CText {
  font-size: 15px !important;
}
.carousel-caption {
  position: absolute;
  right: 8%;
  left: 1%;
  top: 28%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: black;
  text-align: left;
  padding-left: 55px;
  padding-right: 40px;
}
.QRImage {
  height: 100px;
  width: 120px;
  margin-left: 30px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.ScanText {
  background-color: rgb(32, 32, 96);
  border-radius: 8px;
  color: white;
  padding: 2px;
  width: 120px;
  margin-bottom: 25px;
  font-size: 15px !important;
  text-align: center;
}

.bgImage {
  background-image: url("../images/productsimages/electical-panels.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: difference;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.4);
  min-width: 100%;
}

@media (max-width: 700px) {
  .CTextHeading {
    font-size: 22px;
  }

  .CText {
    font-size: 14px !important;
  }

  .bgImage {
    height: 455px;
  }

  .carousel-caption {
    top: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
    color: black;
    text-align: left;
    padding-left: 15px;
    padding-right: 35px;
  }

  .CarouselImage {
    width: 30%;
    height: 260px;
    margin-top: 120px;
    margin-left: 30px;
  }
}
