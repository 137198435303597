.product {
  position: relative;
  width: 50%;
}

.product:hover .overlay {
  bottom: 0;
  height: 300px;
}

.subtext {
  color: rgb(17, 17, 17);
  font-size: 20px;
  position: relative;
  top: 50%;
  left: 60%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.productoverlay {
  position: absolute;
  bottom: 100%;
  left: 70px;
  right: 70px;
  background-color: #ffffff;
  overflow: hidden;
  width: auto;
  height: 0;
  transition: 0.5s ease;
  top: 75px;
}

.productcollections {
  color: rgb(17, 17, 17);
  font-size: 20px;
  position: relative;
  top: 50%;
  left: 55%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.menudropdown {
  float: left;
  overflow: hidden;
  width: 100%;
}


.menudropdown-content {
  display: none;
  position: fixed;
  left: 10%;
  padding-top: 1rem;
}


.menucolumn {
  padding: 1rem;
  vertical-align: bottom;
  align-items: center;
  text-align: center;
}

.dropmenu-image {
  display: none;
  padding: 1rem;
}

.feature-table {
  display: none;
}

@media (min-width: 62em) {
  .feature-table {
    display: block;
  }
}

.zoom {
  transition: transform 0.5s;
  margin: 0 0;
}

.zoom:hover {
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  z-index: 1;
}

.image-center {
  text-align: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.animation {
  background: rgba(230, 236, 247, 0.82);
  overflow: hidden;
  z-index: 2;
}
.FooterHr {
  color: pink;
}
.FLocationIcon {
  font-size: 30px;
  padding: 0px 20px 20px 20px;
}
.FPhoneIcon {
  font-size: 20px !important;
  padding: 24px 20px 20px 20px;
}

.TabText {
  color: gray !important;
  font-size: 16px;
}
.TabText :hover {
  color: black !important;
}
.TabHr {
  background-color: #d8d3d3;
  margin-top: 6px;
  margin-bottom: 8px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
  justify-content: center;
}

.TextCW a {
  color: white !important;
}

.breadcrumb-item .nav-link {
  padding: 0;
  display: flex;
}

.nav-link {
  color: black;
}

.navbar-nav > .nav-link:hover {
  color: #1da9d2 !important;
  /* border-bottom: 2px solid #1da9d2 !important; */
  width: 50%;
}

.nav-link.active {
  color: #1da9d2 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: transparent;
}
.btn-primary {
  width: 215px;
  background-color: transparent !important;
  color: black !important;
  border: 1px solid #897d7d;
}
.btn-primary:hover {
  width: 215px;
  background-color: transparent !important;
  color: black;
  border: 1px solid #897d7d;
}
.MeterDropdown {
  float: right;
  padding: 5px;
}

.alert-box {
  width: 100%;
  padding: 1rem 0.5rem 0;
  border-radius: 5px;
  border: 2px solid rgba(32, 32, 96, 0.811);
  color: rgba(32, 32, 96, 0.811);
  margin-top: 1rem;
  box-shadow: 0px 0px 2px rgba(32, 32, 96, 0.811);
}

.show > .btn-primary.dropdown-toggle {
  color: black;
}

.FooterBox {
  background-color: #647a93;
  color: "white";
  margin-top: 100px;
  color: white !important;
  padding-top: 50px;
}
.FLogo {
  width: 90px;
  height: 70px;
}
.FooterC {
  padding-right: 4%;
  padding-left: 3%;
}
.FText {
  font-size: 18px;
  margin-top: 40px;
}
.FLinkHeader {
  margin-bottom: 40px;
  font-size: 26px;
  margin-top: 40px;
}
.FLink {
  font-size: 14px;
  color: white;
  cursor: pointer;
}
.FLink:hover {
  font-size: 14px;
  color: rgb(32, 32, 96) !important;
}
.Address {
  font-size: 14px;
}
.FooterB {
  background-color: black;
  padding-top: 15px;
  padding-left: 3%;
}
.FCopyright {
  color: gray;
  font-size: 14px;
}
.FooterHr {
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: white;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (max-width: 575px) {
  html,
  body {
    padding: 0px;
    margin: 0px;
    width: 100%;
    overflow-x: hidden;
  }

  .MeterDropdown {
    float: left;
  }
}

@media (max-width: 700px) {
  .zoom {
    width: 183px;
  }

  .CardContainer {
    margin-top: 20px;
  }

  .ProductImage {
    width: 70% !important;
    margin-top: 10%;
    margin-left: 15%;
  }

  .Middle {
    left: 48% !important;
  }
}

@media (max-width: 1050px) {
  .TopNav {
    display: none;
  }
}
.CenterTab {
  text-align: center;
  justify-content: center;
  background-color: white;
  margin-top: 30px;
  border-bottom: 1px solid gray !;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: green;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid green !important;
}
.nav-tabs {
  border-bottom: 1px solid gray;
}
.ListItems {
  list-style: disc;
  font-size: 18px;
}
.Categories {
  color: gray;
}
.ProductHeaderBox {
  background-color: #647a93;
  color: white;
  text-align: center;
}
.ProductB {
  justify-content: center;
  background-color: transparent;
  padding: 5px;
}
.TextCW {
  color: white !important;
}
.TextCB {
  color: black;
}
.ProductsIcon {
  border: 1px solid gray;
  padding: 10px;
  margin-left: 8px;
  color: black;
}
.ProductsIcon:hover {
  background-color: blue;
  color: white;
}
.UserText {
  margin-top: 15px;
  margin-left: 20px;
}
.ProductContainer {
  position: relative;
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.BoxBorder {
  border: "0.5px solid gray" !important;
}
.CardContainer {
  gap: 15px;
}
.SingleTabText {
  margin-right: 50px !important;
}
.ProductTab {
  padding-left: 20px;
  margin-top: 55px;
}
.SingalDigitalText {
  padding-left: 15px;
  font-size: 18px;
}
.CardBox {
  border: 1px solid gray !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.CardText {
  text-align: center;
  font-size: 1rem;
  padding-top: 10px;
}
.DefaultCardText {
  text-align: center;
  font-size: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ProductImage {
  opacity: 1;
  display: block;
  width: 100%;
  min-height: 235px;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.DefaultProductImage {
  opacity: 1;
  display: block;
  width: 100%;
  height: 200px !important;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.Middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 82px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.ProductContainer:hover .ProductImage {
  opacity: 0.3;
  background-color: rgba(0, 0, 0, 0.56);
}

.navbar-nav > .current {
  color: #1da9d2 !important;
  border-bottom: 2px solid #1da9d2 !important;
}

.ProductContainer:hover .Middle {
  opacity: 1;
}

.ReadmoreText {
  background-color: rgb(32, 32, 96);
  color: white;
  font-size: 18px;
  padding: 10px 5px;
  width: 110px;
  text-align: center;
  cursor: pointer;
}
.PFBIcon {
  padding-left: 6px;
  padding-right: 6px;
}
.PTIcon {
  padding-left: 3px;
  padding-right: 3px;
}
.InputIcon {
  margin-top: 22px;
}

.chip {
  background-color: rgb(255, 0, 0);
  float: right;
  padding: 5px;
  border: 2px solid;
  font-size: 16px;
  color: #f2f2f2;
}
