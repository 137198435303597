.menunavbar {
  overflow: hidden;
  background-color: #333;
  font-family: Arial;
}


.menunavbar a {
  float: left;
  font-size: 16px;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}


.menudropdown h6 {
  font-size: 12px;
  color: red;
}


.menudropdown .dropbtn {
  font-size: 12px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: pink;
  font: inherit;
  margin: 0;
}


.menunavbar a:hover,
.menudropdown:hover .menudropbtn {
  background-color: rgb(235, 245, 233);
  text-decoration: none;
}

.menudropdown-content {
  display: none;
}

.menudropdown-content h5 {
  padding-bottom: -0.5rem;
  color: white;
  font-size: medium;
}

.menudropdown:hover .menudropdown-content {
  z-index: 5;
  display: list-item;
  width: 95%;
  background-color: rgb(82, 104, 84);
  bottom: 5%;
  height: 85%;
  border-radius: 20px;
  border: 1px;
}

.menudropdown-content .dropdownLink {
  color: white;
  font-size: 12px;
}


.link-container a {
  display: block;
  text-transform: capitalize;
  font-size: 12px;
  padding-bottom: 2px;
}

.link-container a:hover {
  background: transition;
  border: 1px;
  border-radius: 10px;
}


.menurow:after {
  display: table;
  clear: both;
}

.divider-line {
  border: 1px solid grey;
}

.productimg {
  align-self: center;
}

.imagecenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.overlaydm {
  position: absolute;
  bottom: 100%;
  left: 70px;
  right: 70px;
  background-color: #ffffff;
  overflow: hidden;
  width: auto;
  height: 0;
  transition: 0.5s ease;
  top: 70px;
}

.dropmenu-image hr {
  width: 100px;
}

@media (min-width: 62em) {
  .dropmenu-image {
    display: inline-flex;
    height: 80px;
    width: 70px;
  }
  .menudropdown-content ul {
    list-style-type: none;
    line-height: 3rem;
    margin-left: 20px;
  }
  .menudropdown-content .dropdownLink {
    color: white;
    font-size: 16px;
  }
  .menudropdown:hover .menudropdown-content {
    display: flex;
    padding-top: 1rem;
    position: fixed;
    width: 96%;
    height: auto;
    background-color: rgb(82, 104, 84);
    border-radius: 20px;
    border: 2px;
    padding-left: 10%;
    margin-top: 1px;
    bottom: auto;
  }
  .menudropdown-content h5 {
    padding: 4px;
    color: white;
    font-size: medium;
  }
}

.menudropdown-content ul {
  list-style-type: none;
  line-height: 0.75rem;
  line-break: loose;
}
